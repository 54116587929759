const enum LANGUAGE {
  ES = "es",
  EN = "en",
}
const DEFAULT_LANGUAGE: LANGUAGE = LANGUAGE.ES;

export { DEFAULT_LANGUAGE, LANGUAGE };
export const LIMIT_CHARACTERS_IN_TRANSLATION = 9200;

export const Languages = [
  { title: "Español", value: LANGUAGE.ES },
  { title: "Inglés", value: LANGUAGE.EN },
];

interface ServiceLanguageMap {
  [key: string]: { name: string; slug?: string };
}

export const ServiceLanguages: ServiceLanguageMap = {
  [LANGUAGE.ES]: { name: "Servicios", slug: "servicios" },
  [LANGUAGE.EN]: { name: "Services", slug: "services" },
};

export const AboutUsLanguages: ServiceLanguageMap = {
  [LANGUAGE.ES]: { name: "Nosotros", slug: "equipo" },
  [LANGUAGE.EN]: { name: "AboutUs", slug: "team" },
};

export const TheBakeryLanguages: ServiceLanguageMap = {
  [LANGUAGE.ES]: { name: "TheBakery", slug: "thebakery" },
  [LANGUAGE.EN]: { name: "TheBakery", slug: "thebakery" },
};
