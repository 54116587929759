exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-servicios-digitalizacion-tsx": () => import("./../../../src/pages/servicios-digitalizacion.tsx" /* webpackChunkName: "component---src-pages-servicios-digitalizacion-tsx" */),
  "component---src-templates-blog-blog-category-tsx": () => import("./../../../src/templates/blog/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-blog-category-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blog/PostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-blog-project-template-tsx": () => import("./../../../src/templates/blog/ProjectTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-project-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/home-template.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-legal-template-tsx": () => import("./../../../src/templates/legal-template.tsx" /* webpackChunkName: "component---src-templates-legal-template-tsx" */),
  "component---src-templates-promo-kit-digital-template-tsx": () => import("./../../../src/templates/promo-kit-digital-template.tsx" /* webpackChunkName: "component---src-templates-promo-kit-digital-template-tsx" */),
  "slice---src-slices-contact-us-contact-us-tsx": () => import("./../../../src/slices/ContactUs/ContactUs.tsx" /* webpackChunkName: "slice---src-slices-contact-us-contact-us-tsx" */),
  "slice---src-slices-footer-footer-slice-tsx": () => import("./../../../src/slices/Footer/footer-slice.tsx" /* webpackChunkName: "slice---src-slices-footer-footer-slice-tsx" */),
  "slice---src-slices-latest-projects-latest-projects-tsx": () => import("./../../../src/slices/LatestProjects/LatestProjects.tsx" /* webpackChunkName: "slice---src-slices-latest-projects-latest-projects-tsx" */),
  "slice---src-slices-list-categories-list-categories-tsx": () => import("./../../../src/slices/ListCategories/ListCategories.tsx" /* webpackChunkName: "slice---src-slices-list-categories-list-categories-tsx" */),
  "slice---src-slices-main-menu-main-menu-tsx": () => import("./../../../src/slices/MainMenu/MainMenu.tsx" /* webpackChunkName: "slice---src-slices-main-menu-main-menu-tsx" */)
}

